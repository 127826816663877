.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.75);
  max-width: 600px;
  margin: auto;
}

.eventHeader {
  flex: auto;
  float: center;
  background: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: auto;
  height: auto;
}
.eventSider {
  flex: auto;
  float: right;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  margin: auto;
  padding: 24px;
  display: flex;
  width: max-content;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 0.1;
}

.eventContent {
  min-height: 280px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.75);
  width: auto;
  margin: auto;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.done {
  text-decoration-line: line-through;
  opacity: 0.4;
}

body {
  background-position: center center;
  background-size: cover;
}
.ant-layout {
  background: none;
}
.ant-layout-header {
  background: #fff;
  padding: 0 0 0 24px;
  border-bottom: 1px solid #f0f0f0;
}
.localHost {
  display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    
}


.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  height: 100% !important;
}
.ant-layout-sider {
  position: relative;
  min-width: 0;
  height: inherit;
  background: transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: #f7f7f8;
}

.ant-layout-footer {
  color: black;
  font-weight: 600;
  background: none;
  background: #f7f7f8;
}

.ant-list-bordered {
  border: 2px solid white;
}

.ant-list-item {
  cursor: pointer;
}

.buttonRight {
  float: right;
  border: 2px;
  padding: 10px;
  margin-right: 5px;
}

.icon:hover {
  filter: invert(20%);
}

.stayRight {
  float: right;
  padding: 10px;
}

.ant-menu-horizontal {
  border-bottom: none;
  background: transparent;
}

.welcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-right: 2px;
 

}
.alignPhoto {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-right: 2px;
 

}
